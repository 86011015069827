<template>
  <div>
    <GUNBHeader></GUNBHeader>
    <Header :msg="$t('header_message2')"></Header>
    <div class="main_rows">
    <HeroSection>
      <template #img>
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full image_gunb"
          :alt="$t('hero_image')"
          src="../assets/images/f.png"
        />
      </template>
      <template #text>
        <div class="sm:text-center lg:text-left">
          <h2
            class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-extrabold text-gray-900 sm:text-3xl sm:leading-tight full_text">
            {{ $t('header_message') }}
          </h2>
          <div class="add_text max-w-[798px] mb-10">
            Wyszukiwanie w rejestrze osób posiadających uprawnienia budowlane
          </div>
        </div>
      </template>
      <template #search>
        <DefaultSearch></DefaultSearch>
      </template>
    </HeroSection>
    </div>
    <div class="main_rows_down">
    <div class="down_part">
    <FaqSection></FaqSection>
    </div>
    </div>
    <BannerOneSection></BannerOneSection>
    <BannerTwoSection></BannerTwoSection>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import GUNBHeader from '@/components/GunbHeader.vue'
import HeroSection from '@/components/HeroSection'
import FaqSection from '@/components/FaqSection.vue'
import DefaultSearch from '@/components/DefaultSearch'
import BannerOneSection from '@/components/BannerOneSection.vue'
import BannerTwoSection from '@/components/BannerTwoSection.vue'
import Footer from '@/components/Footer.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    GUNBHeader,
    Footer,
    BannerOneSection,
    BannerTwoSection,
    DefaultSearch,
    HeroSection,
    FaqSection,
    Header
  },
  data() {
    return {
      searchMode: 'default' // default | appraiser | verify-document
    }
  },
  methods: {
    ...mapActions({
      getDisciplines: 'disciplines/getDisciplines',
      getFaqs: 'faqs/getFaqs',
      getRegulations: 'regulations/getRegulations',
      getCategories: 'specialityCategories/getCategories',
      getVoivodeships: 'voivodeships/getVoivodeships'
    }),

    showDefaultSearch() {
      this.prepareSearching()
      this.searchMode = 'default'
    },
    showAppraiserSearch() {
      this.prepareSearching()
      this.searchMode = 'appraiser'
    },
    showVerifyDocument() {
      this.searchMode = 'verify-document'
      if (!this.$store.state.base.hidden) {
        this.$store.commit('base/SET_HIDDEN')
      }
    },
    prepareSearching() {
      if (this.$store.state.base.hidden) {
        this.$store.commit('base/SET_HIDDEN')
      }
      this.$store.commit('professionalCards/SET_PROFESSIONAL_CARDS', [])
      this.$store.commit('appraiserCards/SET_APPRAISER_CARDS', [])
      this.$store.commit('selectedSet/SET_NAMES', '')
      this.$store.commit('selectedSet/SET_DECISION', '')
      this.$store.commit('selectedSet/SET_REGION', null)
      this.$store.commit('selectedSet/SET_CATEGORY', null)
      this.$store.commit('selectedSet/SET_CONTACT_DATA', null)
      this.$store.commit('selectedSet/SET_DISCIPLINE', null)
      this.$store.commit('selectedSet/SET_REGULATION', null)
      this.$store.commit('selectedSet/SET_EXTENT', null)
      this.$store.commit(
        'selectedSet/SET_APPRAISER_REGULATION_SPECIALITY',
        null
      )
      this.$store.commit('selectedSet/SET_APPRAISER_DECISION', '')
    },
    displayTable() {
      return (
        this.$store.state.professionalCards.totalRecords > 0 &&
        this.$store.state.professionalCards.professionalCards.length > 0
      )
    },
    displayAppraiserTable() {
      return (
        this.$store.state.appraiserCards.totalRecords > 0 &&
        this.$store.state.appraiserCards.appraiserCards.length > 0
      )
    },
    displayDefaultSearch() {
      return !this.$store.state.base.hidden && this.searchMode === 'default'
    },
    displayAppraiserSearch() {
      return !this.$store.state.base.hidden && this.searchMode === 'appraiser'
    }
  },
  mounted() {
    this.getDisciplines()
    this.getFaqs()
    this.getVoivodeships()
    this.getCategories()
    this.getRegulations()
  }
}
</script>
<style>
.add_text{
  color: var(--Text-Black_Text, var(--Black_text, #3D3D40));
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  width: 798px;
}
.full_text{
  color: var(--Text-Navy_Text, #339);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  width: 798px;
}
.image_gunb {
  width: 533px;
  height: 523px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.down_part {
  background: white;
  width: 100%;
  padding-top: 4rem;
  min-width: 1500px;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title {
  font-family: Roboto;
}
.main_rows{
  padding-left: 8%;
  padding-right: 8%;
}
.main_rows_down{
  padding-left: 8%;
  padding-right: 8%;
  background: #FFFFFF;
}

.e-toast-warning {
  background-color: #99CCFF;
  color: #005CE6;
}
</style>
