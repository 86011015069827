<template>
  <div class="main_w">
      <div class="px-4 py-5 bg-white sm:p-6 inside">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <TextField
              :labelClass="correctLabelClass"
              :label="correctLabel"
              v-model="selectedNames"
            ></TextField>
          </div>
          <div class="col-span-6 lg:col-span-3">
            <SelectField
              :label="this.$t('region')"
              v-model="selectedRegion"
              :options="voivodeships"
              :optionValue="'id'"
              selectClass="lowercase"
              :optionName="'name'"
            >
              <option value=""></option>
            </SelectField>
          </div>
          <div class="col-span-6 lg:col-span-3">
            <TextField
              :label="this.$t('decision_number')"
              v-model="selectedDecisionNum"
            ></TextField>
          </div>
          <div class="col-span-6">
            <SelectField
              :label="
              this.$t('realm') + ' ' + '(' + this.$t('type_of_permission') + ')'
            "
              v-model="selectedDiscipline"
              :options="disciplines"
              :optionValue="'ids'"
              :optionName="'name'"
            >
              <option value=""></option>
            </SelectField>
          </div>
          <div class="col-span-6">
            <SelectField
              :label="this.$t('category')"
              v-model="selectedCategory"
              :options="categories"
              :optionValue="'id'"
              :optionName="'name'"
            >
              <option value=""></option>
            </SelectField>
          </div>
          <label class="col-span-6 input">
            <input type="checkbox" class="w-4 h-4 text-white-600 bg-blue border-blue-300 rounded "
                   v-model="selectedContactData"
                   />
            Wyświetlaj tylko osoby z danymi kontaktowymi
          </label>
          <transition name="slide-fade">
            <div v-if="selectedCategory" class="col-span-6">
              {{ this.$t('range') }}
              <label class="flex items-center ml-2 mt-4">
                <input
                  class="form-radio"
                  type="radio"
                  value=""
                  v-model="selectedExtent"
                />
                <span class="ml-2">{{ this.$t('all_extents') }}</span>
              </label>
              <label
                v-for="item in extents"
                :key="item.id"
                class="flex items-center ml-2 mt-4"
              >
                <input
                  class="form-radio"
                  type="radio"
                  :value="item.ids"
                  v-model="selectedExtent"
                />
                <span class="ml-2">{{ item.name }}</span>
              </label>
            </div>
          </transition>
          <transition name="slide-fade">
            <div v-if="selectedExtent && extents.length > 0" class="col-span-6 info_box">
              <p class="mb-3 info_box_text">
                <span class="capitalize">{{ this.$t('category') }}</span>
                "{{ categoryName }}" {{ this.$t('in_terms_of') }} "{{
                  extentName
                }}" {{ this.$t('range_info') }}
              </p>
              <div class="border-b border-t">
                <ul class="ml-5 list-disc py-3">
                  <li v-for="(option, index) in findSpecialities" :key="index">
                    {{ option }}
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="px-4 py-3 bg-white text-right sm:px-6 button_down">
        <transition name="slide-fade">
          <button
            v-if="queryNotEmpty"
            @click="startSearching"
            href="#table"
            class="btn-search sbutton"
            v-smooth-scroll
          >
            {{ this.$t('search') }}
          </button>
        </transition>
      </div>
  </div>
</template>

<script>
import TextField from './TextField'
import SelectField from './SelectField'
import { selected } from '../mixins/selected.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DefaultSearch',
  mixins: [selected],
  components: { SelectField, TextField },
  computed: {
    ...mapGetters({
      disciplines: 'disciplines/mapDisciplines',
      voivodeships: 'voivodeships/mapVoivodeships',
      categories: 'specialityCategories/mapCategories',
      categoryName: 'specialityCategories/categoryName',
      extentName: 'specialityCategories/extentName',
      findSpecialities: 'specialityCategories/findSpecialities',
      queryParams: 'selectedSet/getQueryParams',
      mapNames: 'selectedSet/mapNames',
      testNames: 'selectedSet/testNames',
      queryNotEmpty: 'selectedSet/queryNotEmpty',
      queryIsEmpty: 'selectedSet/queryIsEmpty',
      extents: 'specialityCategories/mapExtents'
    }),
    correctLabel() {
      return this.testNames || !this.selectedNames
        ? this.$t('names')
        : this.$t('set_correct_names')
    },
    correctLabelClass() {
      return this.testNames || !this.selectedNames ? '' : 'text-red-700'
    }
  },
  methods: {
    ...mapActions({
      search: 'professionalCards/getProfessionalCards'
    }),
    startSearching() {
      console.info(this.queryParams)
      this.$store.commit('base/SET_RESULT')
      this.search(this.queryParams)
    }
  },
  watch: {
    selectedCategory: {
      handler() {
        this.selectedExtent = null
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.main_w {
  width: 905px;
  min-height: 592px;
  background: #FFFFFF;
  /* shadow_sub */
  box-shadow: 0px 15px 4px rgba(167, 179, 200, 0.02), 0px 9px 4px rgba(167, 179, 200, 0.03), 0px 5px 3px rgba(167, 179, 200, 0.07), 0px 2px 2px rgba(167, 179, 200, 0.1), 0px 1px 1px 1px rgba(167, 179, 200, 0.12);
  border-radius: 16px;
  z-index: 10;
  position: relative;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.button_down {
  position: relative;
  top: 50px;
  border-radius: 16px;
  padding-right: 2rem;
}
.form-select {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M9.33398 13.334L16.0007 20.0007L22.6673 13.334H9.33398Z' fill='%23005CE6'/></svg>");
}

.checkbox_gunb {
  /* Rectangle 482 */

  width: 18px;
  height: 18px;

  /* Colors/Blue1

  old blue text
  */
  background: #005CE6;
  border-radius: 4px;
}

.sbutton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 28px;
  gap: 8px;
  width: 104px;
  height: 48px;
  background: #005CE6;
  border-radius: 48px;
}

.inside {
  /* Admin lokalny / edycja podmiotu */

  box-sizing: border-box;

  /* Auto layout */
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  top: 32px;
  left: 32px;

  width: 841px;
  min-height: 448px;
  left: 32px;
  top: 32px;

  /* Colors/Gray4 */
  border: 1px solid #E0E0E0;
  border-radius: 8px;

}

.info_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  position: relative;
  width: 634px;
  background: #E3EEFF;
  border: 1px solid #99CCFF;
  border-radius: 8px;
  color: #005CE6;
}
.info_box_text {
  font-weight: bold;
}
</style>
