<template>
  <div class="bg-white w-100 py-8 footer_1">
      <div class="w-1/4 footer_grid">
        <a href="https://www.gunb.gov.pl/">
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full footer_img"
          src="../assets/images/f1.png"
        /></a>
      </div>
      <div class="w-1/4 footer_grid">
        <table class="footer_table_cells">
          <tr>
            <td class="footer_td_cell_center">2024 Główny Urząd Nadzoru Budowlanego</td>
          </tr>
        </table>
      </div>
      <div class="w-1/4 footer_grid">
        <table class="footer_table_cells">
          <tr>
            <td class="footer_td_cell_left">
              <a @click="showModal()">Deklaracja dostępności</a></td>
            <td class="footer_td_cell_right">
              <p> </p></td>
          </tr>
        </table>
      </div>
      <div class="w-1/4 footer_grid_images">
        <a href="https://www.linkedin.com/company/gunb">
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full tool_image"
          src="../assets/images/f2.png"
        /></a>
        <a href="https://www.youtube.com/channel/UCE6OMf81seJQ1NCf107U_Eg">
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full tool_image"
          src="../assets/images/f3.png"
        /></a>
        <a href="https://x.com/GUNB_Urzad">
        <img
          class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full tool_image"
          src="../assets/images/f4.png"
        /></a>
      </div>
 </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  methods: {
    showModal() {
      this.$root.$emit('openmodal')
    }
  }
}
</script>

<style>
.footer_1 {
  display: -webkit-box;
  flex-direction: column;
  align-items: center;
  padding: 40px 64px 40px 64px;
  width: 100%;
  height: 126px;
  background: #FFFFFF;
}
.footer_grid {
  display: -webkit-box;
  -webkit-box-pack: center;
}
.footer_grid_images{
  display: flex;
  flex-direction: row;
}

.tool_image {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}
.footer_table_cells {
  width: 100%;
}

.footer_td_cell_left {
  text-align: left;
}

.footer_td_cell_right {
  text-align: right;
}
.footer_td_cell_center {
  vertical-align: middle;
  text-align: center;
}

.footer_img {
  width: 192px;
  height: 32px;
}
</style>
