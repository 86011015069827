<template>
  <div class="items-start flex main_container_two">
    <div class="w-3/6">
      <img
        class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full image_gunb_two"
        :alt="$t('hero_image')"
        src="../assets/images/b2.png"
      />
    </div>
    <div class="w-3/6">
      <div class="body_2">
        <h3 class="heading_3">Użytkowniku czy wiesz że w bazie e-CRUB możesz odszukać interesujących Cię specjalistów z uprawnieniami budowlanymi?</h3><br>
        <p class="text_body_2">e-CRUB to aktualne źródło informacji, z bazą niemal 130 tys. specjalistów z zakresu budownictwa z całej Polski.</p><br>
        <ul class="list-disc">
          <li class="text_body_2 list_item">Określ lokalizację</li>
          <li class="text_body_2 list_item">Zakres kompetencji</li>
          <li class="text_body_2 list_item">Zaznacz opcję „Wyświetlaj tylko osoby z danymi kontaktowymi” w polu wyszukiwarkiu e-mail.</li></ul>
      </div>
</div>
  </div>
</template>

<script>
export default {
  name: 'BannerTwoSection'
}
</script>
<style>
.main_container_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 112px 240px;
  gap: 112px;
  width: 100%;
  height: 562px;
  min-width: 1500px;
  background: #333399;
}

.image_gunb_two {
  /* Obrazek */
  /* Auto layout */
  width: 533px;
  height: 523px;
}
</style>
