<template>
  <div class="items-start flex main_container_one">
    <div class="w-3/6">
      <div class="body_2">
<h3 class="heading_3">Czy wiesz, że możesz zostawić w e-CRUB swoje dane kontaktowe aby móc być widocznym dla inwestorów?</h3>
<br>
      <p class="text_body_2"> </p>
      <ul class="list-disc">
        <li class="text_body_2 list_item">adresu do korespondencji,</li>
        <li class="text_body_2 list_item">numeru telefonu,</li>
        <li class="text_body_2 list_item">adresu e-mail.</li>
      </ul>
      <p class="text_body_2">Umożliwi to bezpośredni kontakt osobom, które poszukują specjalistów z uprawnieniami budowlanymi.</p><br>
        <p class="text_body_2"><a class="zgoda" href="https://www.gunb.gov.pl/sites/default/files/pliki/dokumenty/e-crub_nowa_zgoda_do_udostep_danych_na_str._inter.gunb_23.07.24.pdf">Wzór zgody</a> możesz pobrać z naszej strony internetowej.</p>
        <p class="text_body_2">Wypełniony dokument prześlij do GUNB pocztą tradycyjną lub przez ePUAP.</p>
      </div>
    </div>
    <div class="w-3/6">
      <img
        class="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full image_gunb_one"
        :alt="$t('hero_image')"
        src="../assets/images/b1.png"
      />

</div>
  </div>
</template>

<script>
export default {
  name: 'BannerOneSection'
}
</script>
<style>
.main_container_one {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 112px 240px;
  gap: 112px;
  width: 100%;
  height: 562px;
  min-width: 1500px;
  background: #0066FF;
}
.image_gunb_one {
  /* Obrazek */
  /* Auto layout */
  width: 533px;
  height: 523px;
}
.heading_3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #FFFFFF;
}

.text_body_2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.list_item {
  margin-left: 1rem;
}
.zgoda {
  font-weight:bolder;
}
.body_2 {
  width: 689px;
  height: 264px;
  left: 240px;
  top: 196px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  display: inline-block;
  align-items: center;
  color: #FFFFFF;
}
</style>
