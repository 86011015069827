<template>
   <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Ustawienia dostępności</h2>
      </div>
      <div class="modal-body">
         <div>
            Główny Urząd Nadzoru Budowlanego zapewnia dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.<br>
            Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej portalu e-Budownictwo. Serwis ten jest zgodny z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.<br>
            <br>
            Data publikacji strony internetowej: od 20 kwietnia 2023 r.<br>
            Data ostatniej dużej aktualizacji: 20 kwietnia 2023 r.<br>
            <br>
            <b>Przygotowanie deklaracji dostępności</b><br>
            Data sporządzenia deklaracji: 20 kwietnia 2023 r.<br>
            Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.<br>
            Data ostatniego przeglądu deklaracji: 20 kwietnia 2023 r.<br>
            <br>
            <b>Skróty klawiaturowe</b><br>
            W części publicznej serwisu e-Budownictwo, dostępnej bez logowania, można korzystać ze standardowych skrótów klawiaturowych. Po zalogowaniu dostępne są dodatkowe skróty klawiszowe, ułatwiające nawigację pomiędzy sekcjami portalu oraz pomiędzy treściami w poszczególnych sekcjach. Opis skrótów można znaleźć w module ułatwień dostępności.<br>
            <br>
            <b>Informacje zwrotne i dane kontaktowe</b><br>
            Jesteśmy świadomi tego, że dostępność jest procesem, nad którym pracujemy każdego dnia. Staramy się zapewnić dostępność strony internetowej, jednak jeżeli znajdziesz błędy lub masz uwagi prosimy o kontakt pod adres e-mail:<br> koordynator.dostepnosci@gunb.gov.pl<br>
            <br>
            W ten sposób można również składać wnioski o udostępnienie informacji niedostępnej i składać żądania zapewnienia dostępności. Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, np. przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji, itp.<br>
            <br>
            <b>Żądanie powinno zawierać:</b><br>
            1. dane osoby zgłaszającej żądanie,<br>
            2. wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz<br>
            3. sposób kontaktu.<br>
            Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji.<br>
            Główny Urząd Nadzoru Budowlanego zrealizuje żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, niezwłocznie poinformuje o tym wnoszącego żądanie oraz poinformuje o terminie realizacji żądania, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem.<br>
            Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, Główny Urząd Nadzoru Budowlanego zaproponuje alternatywny sposób dostępu do informacji. W przypadku, gdy Główny Urząd Nadzoru Budowlanego odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej.
            Po wyczerpaniu wszystkich możliwości skargę można przesłać także do Rzecznika Praw Obywatelskich.
          </div>
        </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="$emit('close')" data-dismiss="modal">Zamknij</button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    label: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      required: false
    }
  }
}
</script>
<style>
.select-field {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 0px;
gap: 10px;
height: 48px;

background: #FFFFFF;
/* Colors/Gray3 */
border: 1px solid #CCCCCC;
border-radius: 4px;
}
.modal .modal-dialog .modal-content {
  padding: 20px 40px 40px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.modal .modal-dialog .modal-header {
  border: 0;
  position: relative;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.modal .modal-dialog .modal-body {
  margin-bottom: 25px;
  font-size: 85%;
  text-align: left;
}
.modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
#dostepnosc .tab-content {
  border: 2px solid #eceff2;
  border-radius: 8px;
  padding: 30px;
  margin-top: 30px;
}
.modal .modal-dialog .modal-header h2 {
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 120%;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #51b6dc;
  border: 1px solid #51b6dc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn:focus, .btn:hover {
  text-decoration: none;
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

.btn {
  font-size: 79%;
  text-transform: none;
  border: 1px solid #005ce6;
  border-radius: 24px;
  padding: 8px 12px;
}
</style>
